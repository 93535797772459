import {
  postLBFeedData,
  postLBFeedFollowData,
  postLBFeedFavoriteData,
  postLBFeedHighFiveData,
  postLBFeedFilterData,
  postLBFeedFilterTotalData,
  postLBFeedRecentFilterData,
  postLBFeedQuizData
} from '@/api'
import cookies from 'js-cookie'
import { getLBData } from '@/api'
import { getDateFromIssueYmdt } from '@/utils/dateUtils'

const defaultParams = {
  MallAccessToken: null,
  uid: cookies.get('memberCummunityUID'),
  appType: cookies.get('appType'),
  appVersion: cookies.get('appVersion'),
  languageCode: 'ko',
  countryCode: '82',
  whereToUseCode: 'FEED',
  utcInterval: -540,
  memberNo: 0,
  logCode: '',
}

const ENDPOINT_MAINFEED = 'Feed/MainFeed'
const ENDPOINT_TARGETUSER_FEED = 'Feed/TargetUserFeed'
const ENDPOINT_FAVORITE_FEED = 'Feed/FavoriteFeed'

export default {
  namespaced: true,
  state: {
    page: 1,
    bottomModalView: false,
    bottomModalData: {},
    tempBottomModalData: {},
    tempFilterValueObject:{},
    dataType: 'tip',
    ProfileDataType: 'total',
    filter: null,
    filterTitle: null,
    filterTempModel: null,
    filterOption: {
      seeMore: false,
      campaign: true,
    },
    selectedValue: 0,
    ProfileSelectedValue: 0,
    feedRecentFilterDataArray: [],
    feedRecentFilterData: null,
    queryData: {
      filterValueObject: {
        campaignCodeArrayString: null,
        genderCode: null,
        ageGroupCodeArrayString: null,
        characteristicTagCodeArrayString: null,
        recordMealCodeArrayString: null,
        mealProteinCodeArrayString: null,
        mealInevitableSituationCodeArrayString: null,
        mealCookingDifficultyCodeArrayString: null,
        mealSituationCodeArrayString: null,
        workoutTypeCodeArrayString: null,
        tipTypeCodeArrayString: null,
        tabOrderCode: null,
      },
      feedCategoryCode: 'TIP',
      isFullFilterSearch: false,
      page: 1,
      ...defaultParams
    },
    ProfileQueryData: {
      filterValueObject: {
        campaignCodeArrayString: null,
        genderCode: null,
        ageGroupCodeArrayString: null,
        characteristicTagCodeArrayString: null,
        recordMealCodeArrayString: null,
        mealProteinCodeArrayString: null,
        mealInevitableSituationCodeArrayString: null,
        mealCookingDifficultyCodeArrayString: null,
        mealSituationCodeArrayString: null,
        workoutTypeCodeArrayString: null,
        tipTypeCodeArrayString: null,
        tabOrderCode: null,
      },
      feedCategoryCode: 'ALL',
      isFullFilterSearch: false,
      page: 1,
      // uId: '1161_NoEqupiMB',
      // mallAccessToken: null,
      // memberNo: 0,
      // appType: 'ios',
      // appVersion: '2.4',
      // languageCode: 'ko',
      // countryCode: '82',
      // whereToUseCode: 'FEED',
      // logCode: 'string',
      // utcInterval: -540,
      ...defaultParams
    },
    queryFilterData: {
      feedCategoryCode: 'TIP',
      ...defaultParams
    },
    queryRecentFilterData: {
      ...defaultParams
    },
    followQueryData: {
      followUId: '팔로잉 대상 아이디',
      followType: 'USER',
      isOk: true,
      ...defaultParams
    },
    favoriteQueryData: {
      feedGroupCode: 'string',
      feedGroupTypeCode: 'string',
      feedGroupCategoryCode: 'string',
      feedIndexKey: 'string',
      isOk: true,
      userName: '',
      ...defaultParams
    },
    highFiveQueryData: {
      feedGroupCode: 'string',
      feedGroupTypeCode: 'string',
      feedGroupCategoryCode: 'string',
      feedIndexKey: 'string',
      isOk: true,
      userName: '',
      ...defaultParams
    },

    feedData: [],
    feedFilterData: [],
    feedFilterTotalData: null,
    testData: [],
    routerBack: false,
    ProfileFeedData: [],
    FavoriteFeedData: [],
    pageTypeCode: '',
    followTab: '',

    //type 0 단일선택, type1 복수선택, type2 활성화선택, type3 이벤트선택
    testFilterData: [
      {
        name: 'year',
        model: [],
        type: 1,
        itemTitle: '최신순(추천)',
        item: ['인기순', '추천순', '최신순'],
      },
    ],

    testFilterDataMeal: [],
    testFilterDataWorkout: [],
    testFilterDataTip: [],
    testFilterPageData: [
      {
        title: '이 때 먹기 좋은 식단',
        data: [
          {
            selected: false,
            text: '아침',
          },
          {
            selected: false,
            text: '점심',
          },
          {
            selected: false,
            text: '간식',
          },
          {
            selected: false,
            text: '저녁',
          },
          {
            selected: false,
            text: '야식',
          },
        ],
      },
      {
        title: '피할 수 없는 상황',
        data: [
          {
            selected: false,
            text: '치팅',
          },
          {
            selected: false,
            text: '회식',
          },
          {
            selected: false,
            text: '외식',
          },
          {
            selected: false,
            text: '약속',
          },
        ],
      },
      {
        title: '포함된 단백질 음식',
        data: [
          {
            selected: false,
            text: '닭가슴살',
          },
          {
            selected: false,
            text: '닭가슴살 가공품',
          },
        ],
      },
      {
        title: '단백질이 먹기 힘들어요',
        data: [
          {
            selected: false,
            text: '#닭가슴살맛있게',
          },
          {
            selected: false,
            text: '#단백질풍부하게',
          },
          {
            selected: false,
            text: '#닭가슴살대체',
          },
          {
            selected: false,
            text: '#단백질파우더활용법',
          },
        ],
      },
      {
        title: '이럴 때 참기 힘들어요',
        data: [
          {
            selected: false,
            text: '#빵당길때',
          },
          {
            selected: false,
            text: '#빵당길때',
          },
          {
            selected: false,
            text: '#단게당길때',
          },
          {
            selected: false,
            text: '#치킨당길때',
          },
        ],
      },
      {
        title: '함께 구성하기 힘들어요',
        data: [
          {
            selected: false,
            text: '#편의점에서',
          },
          {
            selected: false,
            text: '#단백질급속충전',
          },
          {
            selected: false,
            text: '#한끼도시락',
          },
          {
            selected: false,
            text: '#다이어트샌드위치',
          },
          {
            selected: false,
            text: '#등산할때',
          },
        ],
      },
      {
        title: '특별한 한 끼',
        data: [
          {
            selected: false,
            text: '슈퍼클린',
          },
          {
            selected: false,
            text: '비건',
          },
        ],
      },
      {
        title: '준비',
        data: [
          {
            selected: false,
            text: '초간단',
          },
          {
            selected: false,
            text: '간단',
          },
          {
            selected: false,
            text: '정성',
          },
        ],
      },
    ],
    isDSFreEatenFoodModalOpen: false,
    notToBeMissedContensData: [],
    loading: false,
    feedType: 'MAIN'
  },
  actions: {
    async postLBFeedQuizDataTrigger({ state, commit, dispatch }, options) {
      let payload = {
        ...options,
        ...defaultParams
      }
      const resp = await postLBFeedQuizData('', 'post', payload)
    },

    async postLBFeedDataTrigger({ state, commit, dispatch }, options) {
      state.loading = true
      const resp = await postLBFeedData('', 'post', state.queryData)
      state.feedData = resp.data
      state.loading = false

      if (state.queryData.feedCategoryCode === 'TIP') {
        /**
         * 피드 '꿀팁' 탭 2번째
            노출기한: ~10/15 15시
            노출대상: 전체 (혹시 지정하는 것도 별도의 작업 필요 없다면 여성만, 더 가능하다면 19~48세 여성으로)
            랜딩: https://paycrew.learnbody.co.kr/apply?UID={UID}
        */
       // 날짜 체크 
        let isExpired = false

        // ~10/22 15시
        let dateStart = getDateFromIssueYmdt('2023-07-24 11:00:00')
        let dateExpire = getDateFromIssueYmdt('2024-01-28 23:59:59')
        let now = new Date()
        if (dateStart <= now && now < dateExpire) isExpired = false
        else isExpired = true

        // 여성 & 18~49세
        let userAge = cookies.get('userAge')
        userAge = userAge !== undefined && userAge !== null ? Number(userAge) : 0
        let userGender = cookies.get('userGender')
        userGender = userGender === undefined || userAge === null ? '' : userGender

        if ((userAge >= 18 && userAge <= 49)
        && 'F' === userGender
        && !isExpired) {
          let crewBannerInfo = {
            feedGroupCode: 'COMMUNITY',
            feedGroupTypeCode: 'EVENT',
            feedGroupCategoryCode: 'BANNER',
            feedIndexKey: '1',
            feedTypeCode: 'BANNER',
            feedItemBanner: {
              viewCode: 3,
              rendingCode: '',
              rendingData: '',
              outLinkURL: 'https://paycrew.learnbody.co.kr/apply?UID=' + cookies.get('memberCummunityUID'),
              bannerImageUrl: 'https://learnbodykr.blob.core.windows.net/community-images/Banner/FeedBanner_LBCRew_231012.png?v=2024012211'
            }
          }
    
          if (state.feedData.feedList.length > 2) {
            state.feedData.feedList.splice(1,0, crewBannerInfo)
          } else {
            state.feedData.feedList.push(crewBannerInfo)
          }
        }

        /**
         * 챌린지 배너 강제 추가 (임시)
         * 피드 '꿀팁' 탭 3번째
         * 노출기한: 무제한
         * 노출대상: 전체
         * 랜딩: 챌린지 탭
         */
        dateExpire = getDateFromIssueYmdt('2023-12-22 14:59:59')
        if (dateStart <= now && now < dateExpire) isExpired = false
        else isExpired = true

        if (!isExpired) {
          let bannerInfo = {
            feedGroupCode: 'COMMUNITY',
            feedGroupTypeCode: 'EVENT',
            feedGroupCategoryCode: 'BANNER',
            feedIndexKey: '0',
            feedTypeCode: 'BANNER',
            feedItemBanner: {
              viewCode: 3,
              rendingCode: '',
              rendingData: '',
              outLinkURL: 'https://learnbodychallenge.com',
              bannerImageUrl: 'https://learnbodykr.blob.core.windows.net/community-images/Banner/FeedBanner_LBC_230904.png?v=2023121511'
            }
          }
    
          if (state.feedData.feedList.length > 3) {
            state.feedData.feedList.splice(2,0, bannerInfo)
          } else {
            state.feedData.feedList.push(bannerInfo)
          }
        }
      }
    },

    async postLBFeedDataAddTrigger({ state, commit, dispatch }, options) {
      const resp = await postLBFeedData('', 'post', state.queryData)

      state.feedData.feedList.push(...resp.data.feedList)
    },
    async postLBFeedFollowDataTrigger({ state, commit, dispatch }, options) {
      const resp = await postLBFeedFollowData('', 'post', state.followQueryData)
      state.feedData.feedList[options].profile.isFollow = !state.feedData.feedList[options].profile.isFollow
    },
    async postLBFavoriteFeedFollowDataTrigger({ state, commit, dispatch }, options) {
      const resp = await postLBFeedFollowData('', 'post', state.followQueryData)
      state.FavoriteFeedData.feedList[options].profile.isFollow = !state.FavoriteFeedData.feedList[options].profile.isFollow
    },
    async postLBFeedHighFiveDataTrigger({ state, commit, dispatch }, options) {
      state.highFiveQueryData.userName = options.userName
      const resp = await postLBFeedHighFiveData('', 'post', state.highFiveQueryData)
      
      let feedData = null
      if ('PROFILE' === state.pageTypeCode) {
        if ('MYFEED' === options.tabCode) {
          feedData = state.ProfileFeedData
        } else if ('FAVORITE' === options.tabCode) {
          feedData = state.FavoriteFeedData  
        }
      } else {
        feedData = state.feedData
      }

      switch (feedData.feedList[options.index].feedTypeCode) {
        case 'POST':
          feedData.feedList[options.index].feedItemPost.highFiveCount = resp.data.result
          feedData.feedList[options.index].feedItemPost.isHighFive = true
          break

        case 'POST_STORY_V2':
          feedData.feedList[options.index].feedItemPostStory.detail.highFiveCount = resp.data.result
          feedData.feedList[options.index].feedItemPostStory.detail.isHighFive = true
          break

        case 'DIARY_MEAL':
          feedData.feedList[options.index].feedItemDiaryMeal.highFiveCount = resp.data.result
          feedData.feedList[options.index].feedItemDiaryMeal.isHighFive = true
          break

        case 'CAMPAIGN':
          feedData.feedList[options.index].feedItemPostCampaign.detail.highFiveCount = resp.data.result
          feedData.feedList[options.index].feedItemPostCampaign.detail.isHighFive = true
          break

        case 'DIARY_WORKOUT':
          feedData.feedList[options.index].feedItemDiaryWorkout.highFiveCount = resp.data.result
          feedData.feedList[options.index].feedItemDiaryWorkout.isHighFive = true
          break

        case 'MARKET_HOTDEAL':
          feedData.feedList[options.index].feedItemDiaryWorkout.highFiveCount = resp.data.result
          feedData.feedList[options.index].feedItemDiaryWorkout.isHighFive = true
          break

        default:
          break
      }
    },

    async postLBFeedFavoriteDataTrigger({ state, commit, dispatch }, options) {
      state.favoriteQueryData.userName = options.userName
      const resp = await postLBFeedFavoriteData('', 'post', state.favoriteQueryData)

      let feedData = null
      if ('PROFILE' === state.pageTypeCode) {
        if ('MYFEED' === options.tabCode) {
          feedData = state.ProfileFeedData
        } else if ('FAVORITE' === options.tabCode) {
          feedData = state.FavoriteFeedData  
        }
      } else {
        feedData = state.feedData
      }

      switch (feedData.feedList[options.index].feedTypeCode) {
        case 'POST':
          feedData.feedList[options.index].feedItemPost.favoriteCount = resp.data.result
          feedData.feedList[options.index].feedItemPost.isFavorite =
            !feedData.feedList[options.index].feedItemPost.isFavorite
          break

        case 'POST_STORY_V2':
          feedData.feedList[options.index].feedItemPostStory.detail.favoriteCount = resp.data.result
          feedData.feedList[options.index].feedItemPostStory.detail.isFavorite =
            !feedData.feedList[options.index].feedItemPostStory.detail.isFavorite
          break

        case 'DIARY_MEAL':
          feedData.feedList[options.index].feedItemDiaryMeal.favoriteCount = resp.data.result
          feedData.feedList[options.index].feedItemDiaryMeal.isFavorite =
            !feedData.feedList[options.index].feedItemDiaryMeal.isFavorite
          break

        case 'CAMPAIGN':
          feedData.feedList[options.index].feedItemPostCampaign.detail.favoriteCount = resp.data.result
          feedData.feedList[options.index].feedItemPostCampaign.detail.isFavorite =
            !feedData.feedList[options.index].feedItemPostCampaign.detail.isFavorite
          break

        case 'DIARY_WORKOUT':
          feedData.feedList[options.index].feedItemDiaryWorkout.favoriteCount = resp.data.result
          feedData.feedList[options.index].feedItemDiaryWorkout.isFavorite =
            !feedData.feedList[options.index].feedItemDiaryWorkout.isFavorite
          break

        default:
          break
      }
    },
    async postLBFeedFilterDataTrigger({ state, commit, dispatch }, options) {
      const resp = await postLBFeedFilterData('', 'post', state.queryFilterData)
      state.feedFilterData = resp.data
    },

    async postLBFeedRecentFilterDataTrigger({ state, commit, dispatch }, options) {
      const resp = await postLBFeedRecentFilterData('', 'post', state.queryRecentFilterData)
      state.feedRecentFilterData = resp.data

      state.feedRecentFilterDataArray = []
      const d1 = state.feedRecentFilterData.mealCookingDifficultyCodeList
      const e1 = state.feedFilterData.mealCookingDifficultyFilterInfo.mealCookingDifficultyList

      if (d1) {
        for (var i = 0; i < d1.length; i += 1) {
          state.feedRecentFilterDataArray.push(e1.filter((data) => data.code == d1[i])[0].text)
        }
      }
      const d2 = state.feedRecentFilterData.mealProteinCodeList
      const e2 = state.feedFilterData.mealProteinFilterInfo.mealProteinList

      if (d2) {
        for (i = 0; i < d2.length; i += 1) {
          let filteredData = e2.filter((data) => data.diaryMealProteinCode == d2[i])[0]
          if (filteredData) {
            state.feedRecentFilterDataArray.push(
              filteredData.diaryMealProteinText,
            )
          }
        }
      }
      const d3 = state.feedRecentFilterData.recordMealCodeList
      const e3 = state.feedFilterData.recordMealFilterInfo.recordMealList

      if (d3) {
        for (i = 0; i < d3.length; i += 1) {
          state.feedRecentFilterDataArray.push(
            e3.filter((data) => data.diaryRecordMealCode == d3[i])[0].diaryRecordMealText,
          )
        }
      }
      const d4 = state.feedRecentFilterData.mealInevitableSituationCodeList
      const e4 = state.feedFilterData.mealInevitableSituationFilterInfo.mealInevitableSituationList

      if (d4) {
        for (i = 0; i < d4.length; i += 1) {
          state.feedRecentFilterDataArray.push(
            e4.filter((data) => data.diaryMealInevitableSituationCode == d4[i])[0].diaryMealInevitableSituationText,
          )
        }
      }
      const d5 = state.feedRecentFilterData.mealSituationCodeList
      const e5 = state.feedFilterData.mealSituationFilterInfo.mealSituationList

      if (d5) {
        for (i = 0; i < d5.length; i += 1) {
          state.feedRecentFilterDataArray.push(e5.filter((data) => data.code == d5[i])[0].text)
        }
      }
    },

    async postLBFeedFilterTotalDataTrigger({ state, commit, dispatch }, options) {
      const resp = await postLBFeedFilterTotalData('', 'post', state.queryData)

      state.feedFilterTotalData = resp.data
    },

    async postLBFeedMainDataTrigger ({ state, commit, dispatch }, options) {
      let payload = {
        ...state.queryData,
        ...defaultParams
      }
      delete payload.feedCategoryCode
      const resp = await getLBData(ENDPOINT_MAINFEED, 'post', payload)
      state.feedData = resp.data
    },
    async postLBFeedMainDataAddTrigger ({ state, commit, dispatch }, options) {
      let payload = {
        ...state.queryData,
        ...defaultParams
      }
      delete payload.feedCategoryCode
      const resp = await getLBData(ENDPOINT_MAINFEED, 'post', payload)
      state.feedData.feedList.push(...resp.data.feedList)
    },
    async postLBProfileDataTrigger({ state, commit, dispatch }, options) {
      let payload = {
        ...options,
        ...state.ProfileQueryData,
        ...defaultParams
      }
      delete payload.filterValueObject
      delete payload.isFullFilterSearch
      const resp = await getLBData(ENDPOINT_TARGETUSER_FEED, 'post', payload)
      state.ProfileFeedData = resp.data
    },
    async postLBProfileDataAddTrigger({ state, commit, dispatch }, options) {
      let payload = {
        ...options,
        ...state.ProfileQueryData,
        ...defaultParams
      }
      delete payload.filterValueObject
      delete payload.isFullFilterSearch
      const resp = await getLBData(ENDPOINT_TARGETUSER_FEED, 'post', payload)
      state.ProfileFeedData.feedList.push(...resp.data.feedList)
    },
    async postLBFavoriteFeedDataTrigger({ state, commit, dispatch }, options) {
      let payload = {
        ...state.ProfileQueryData,
        ...defaultParams
      }
      delete payload.filterValueObject
      delete payload.page
      delete payload.isFullFilterSearch
      const resp = await getLBData(ENDPOINT_FAVORITE_FEED, 'post', payload)
      state.FavoriteFeedData = resp.data
    },
    async postDetailPageFollowDataTrigger({ state, commit, dispatch }, options) {
      const resp = await postLBFeedFollowData('', 'post', state.followQueryData)
      state.notToBeMissedContensData[options].profile.isFollow = !state.notToBeMissedContensData[options].profile.isFollow
    },
  },
  mutations: {
    SET_SELECTED_VALUE (state, num) {
      state.selectedValue = num
    },
    INIT_FILTER_VALUE_OBJ (state) {
      state.queryData.filterValueObject = {
        campaignCodeArrayString: null,
        genderCode: null,
        ageGroupCodeArrayString: null,
        characteristicTagCodeArrayString: null,
        recordMealCodeArrayString: null,
        mealProteinCodeArrayString: null,
        mealInevitableSituationCodeArrayString: null,
        mealCookingDifficultyCodeArrayString: null,
        mealSituationCodeArrayString: null,
        workoutTypeCodeArrayString: null,
        tipTypeCodeArrayString: null,
        tabOrderCode: null,
      }
    },
    INIT_FEED_FILTER_DATA (state) {
      state.feedFilterData = []
    },
    /**
     * 성공스토리 상세에서 즐겨찾기 클릭시 피드 데이터 동기화
     * data 구조
     * {  
          key: '',
          favoriteCount: 1,
          isActiveFavorite: true / false
        }
     */
    SYNC_FEED_STORY_FAVORITE_DATA (state, data) {
      if (state.feedData?.feedList?.length > 0) {
        let filteredItem = state.feedData.feedList.filter(
          (item) => item.feedTypeCode === 'POST_STORY_V2' && item.feedItemPostStory?.detail.imitateOrderIdx + '' === data.key
        )[0]
        if (filteredItem) {
          filteredItem.feedItemPostStory.detail.favoriteCount = data.favoriteCount
          filteredItem.feedItemPostStory.detail.isFavorite = data.isActiveFavorite
        } 
      }
    },
    /**
     * 성공스토리 상세에서 하이파이브 클릭시 피드 데이터 동기화
     * data 구조
     * {  
          key: '',
          highFiveCount: 1,
          isActiveHighFive: true / false
        }
     */
    SYNC_FEED_STORY_HIGHFIVE_DATA (state, data) {
      if (state.feedData?.feedList?.length > 0) {
        let filteredItem = state.feedData.feedList.filter(
          (item) => item.feedTypeCode === 'POST_STORY_V2' && item.feedItemPostStory?.detail.imitateOrderIdx + '' === data.key
        )[0]
        if (filteredItem) {
          filteredItem.feedItemPostStory.detail.highFiveCount = data.highFiveCount
          filteredItem.feedItemPostStory.detail.isHighFive = data.isActiveHighFive
        } 
      }
    },
    /**
     * 운동/식단 상세에서 즐겨찾기 클릭시 피드 데이터 동기화
     * data 구조
     * {  
          type: '',
          key: '',
          favoriteCount: 1,
          isActiveFavorite: true / false
        }
     */
    SYNC_FEED_FAVORITE_DATA (state, data) {
      if (state.feedData?.feedList?.length > 0) {

        let feedTypeCode = ''

        if ('MEAL' === data.type) feedTypeCode = 'DIARY_MEAL'
        else if ('WORKOUT' === data.type) feedTypeCode = 'DIARY_WORKOUT'

        let filteredItem = state.feedData.feedList.filter(
          (item) => item.feedTypeCode === feedTypeCode && item.feedIndexKey + '' === data.key
        )[0]
        if (filteredItem) {
          switch (feedTypeCode) {
            case 'POST':
              filteredItem.feedItemPost.favoriteCount = data.isActiveFavorite ? filteredItem.feedItemPost.favoriteCount + 1 : filteredItem.feedItemPost.favoriteCount - 1
              filteredItem.feedItemPost.isFavorite = data.isActiveFavorite
              break
    
            case 'DIARY_MEAL':
              filteredItem.feedItemDiaryMeal.favoriteCount = data.isActiveFavorite ? filteredItem.feedItemDiaryMeal.favoriteCount + 1 : filteredItem.feedItemDiaryMeal.favoriteCount - 1
              filteredItem.feedItemDiaryMeal.isFavorite = data.isActiveFavorite
              break
    
            case 'CAMPAIGN':
              filteredItem.feedItemPostCampaign.detail.favoriteCount = data.isActiveFavorite ? filteredItem.feedItemPostCampaign.detail.favoriteCount + 1 : filteredItem.feedItemPostCampaign.detail.favoriteCount - 1
              filteredItem.feedItemPostCampaign.detail.isFavorite = data.isActiveFavorite
              break
    
            case 'DIARY_WORKOUT':
              filteredItem.feedItemDiaryWorkout.favoriteCount = data.isActiveFavorite ? filteredItem.feedItemDiaryWorkout.favoriteCount + 1 : filteredItem.feedItemDiaryWorkout.favoriteCount - 1
              filteredItem.feedItemDiaryWorkout.isFavorite = data.isActiveFavorite
              break
    
            default:
              break
          }
        } 
      }
    },
    /**
     * 운동/식단 상세에서 하이파이브 클릭시 피드 데이터 동기화
     * data 구조
     * {  
          key: '',
          highFiveCount: 1,
          isActiveHighFive: true / false
        }
     */
    SYNC_FEED_HIGHFIVE_DATA (state, data) {
      if (state.feedData?.feedList?.length > 0) {

        let feedTypeCode = ''

        if ('MEAL' === data.type) feedTypeCode = 'DIARY_MEAL'
        else if ('WORKOUT' === data.type) feedTypeCode = 'DIARY_WORKOUT'

        let filteredItem = state.feedData.feedList.filter(
          (item) => item.feedTypeCode === feedTypeCode && item.feedIndexKey + '' === data.key
        )[0]
        if (filteredItem) {
          switch (feedTypeCode) {
            case 'POST':
              filteredItem.feedItemPost.highFiveCount = filteredItem.feedItemPost.highFiveCount + 1
              filteredItem.feedItemPost.isHighFive = true
              break

            case 'DIARY_MEAL':
              filteredItem.feedItemDiaryMeal.highFiveCount = filteredItem.feedItemDiaryMeal.highFiveCount + 1
              filteredItem.feedItemDiaryMeal.isHighFive = true
              break
    
            case 'CAMPAIGN':
              filteredItem.feedItemPostCampaign.detail.highFiveCount = filteredItem.feedItemPostCampaign.detail.highFiveCount + 1
              filteredItem.feedItemPostCampaign.detail.isHighFive = true
              break
    
            case 'DIARY_WORKOUT':
              filteredItem.feedItemDiaryWorkout.highFiveCount = filteredItem.feedItemDiaryWorkout.highFiveCount + 1
              filteredItem.feedItemDiaryWorkout.isHighFive = true
              break

            default:
              break
          }
        } 
      }
    },
    /**
     * 세모운 상세에서 즐겨찾기 클릭시 피드 데이터 동기화
     * data 구조
     * {  
          key: '',
          favoriteCount: 1,
          isActiveFavorite: true / false
        }
     */
    SYNC_FEED_CAMPAIGN_FAVORITE_DATA (state, data) {
      if (state.feedData?.feedList?.length > 0) {
        let filteredItem = state.feedData.feedList.filter(
          (item) => item.feedTypeCode === 'CAMPAIGN' && item.feedItemPostCampaign?.detail.campaignIdx + '' === data.key
        )[0]
        if (filteredItem) {
          filteredItem.feedItemPostCampaign.detail.favoriteCount = data.favoriteCount
          filteredItem.feedItemPostCampaign.detail.isFavorite = data.isActiveFavorite
        } 
      }
    },
    /**
     * 세모운 상세에서 하이파이브 클릭시 피드 데이터 동기화
     * data 구조
     * {  
          key: '',
          highFiveCount: 1,
          isActiveHighFive: true / false
        }
     */
    SYNC_FEED_CAMPAIGN_HIGHFIVE_DATA (state, data) {
      if (state.feedData?.feedList?.length > 0) {
        let filteredItem = state.feedData.feedList.filter(
          (item) => item.feedTypeCode === 'CAMPAIGN' && item.feedItemPostCampaign?.detail.campaignIdx + '' === data.key
        )[0]
        if (filteredItem) {
          filteredItem.feedItemPostCampaign.detail.highFiveCount = data.highFiveCount
          filteredItem.feedItemPostCampaign.detail.isHighFive = data.isActiveHighFive
        } 
      }
    },
  }
}
